/* Eric Meyer's Reset CSS v2.0 - http: //cssreset.com */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
  outline: none;
}
html,
body {
  position: relative;
  width: 100%;
  min-height: 100%;
}
button,
input[type=submit],
input[type=button],
.submit,
.btn {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-appearance: none;
  border-radius: 0;
}
input,
textarea {
  font-family: inherit;
  border-radius: 0;
}
::placeholder {
  color: inherit;
  opacity: 1;
}
em {
  font-style: italic;
}
a {
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
p:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
hr {
  display: block;
  height: 2px;
  border: none;
  padding: 0;
  clear: both;
}
svg {
  display: inline-block;
  overflow: hidden;
  width: 100%;
}
html {
  font-size: 16px;
}
html,
body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
}
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
.notification {
  padding: 15px 0;
  color: red;
  opacity: 0;
  transition: all .3s ease;
}
.notification.visible {
  opacity: 1;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
.btn {
  position: relative;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  border-width: 0;
  outline: none;
  background-color: #4262d7;
  color: #fff;
  transition: background-color 0.3s;
  cursor: pointer;
}
.btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  padding-top: 0;
  border-radius: 100%;
  background-color: rgba(236, 240, 241, 0.3);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.btn:active:before {
  width: 120%;
  padding-top: 120%;
  transition: width 0.2s ease-out, padding-top 0.2s ease-out;
}
.btn > * {
  position: relative;
}
.btn span {
  display: block;
  text-transform: capitalize;
  padding: 12px 24px;
}
.table {
  width: 100%;
}
.table td,
.table th {
  padding: 10px;
}
.table thead th {
  border-top: 4px solid #aabcfe;
  background-color: #b9c9fe;
  color: #039;
  font-weight: 700;
  text-align: left;
}
.table tbody tr {
  border-bottom: 1px solid #fff;
}
.table tbody tr:last-of-type {
  border-bottom: 0;
}
.table tbody tr:nth-of-type(even) td {
  background-color: rgba(232, 237, 255, 0.49);
}
.table tbody tr td {
  color: #669;
  background-color: #e8edff;
}
.table tbody tr td:focus {
  background-color: #fff;
}
.table tbody tr td > span {
  margin-right: 10px;
}
.openForm {
  cursor: pointer;
  padding: 5px 0;
}
.formHolder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 1px);
  background-color: #5356ad;
}
.formHolder form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}
.formHolder form h1 {
  font-size: 2rem;
  margin-bottom: 30px;
}
.formHolder form input {
  display: inline-block;
  border: 1px solid #cecece;
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  padding: 0 10px;
}
.formHolder form input[type="submit"] {
  width: 150px;
  cursor: pointer;
  border: 1px solid #84bbf3;
}
.formHolder__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 840px;
  height: 480px;
  background-color: #999ede;
}
.formHolder__box:before,
.formHolder__box:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  margin: auto;
  background-color: #9297e0;
  transform: rotateX(52deg) rotateY(15deg) rotateZ(-38deg);
  width: 300px;
  height: 285px;
  transition: all .5s ease;
  z-index: 1;
}
.formHolder__box:before {
  top: 50%;
  transform: translateY(-50%) rotateX(52deg) rotateY(15deg) rotateZ(-38deg);
}
.formHolder__box:after {
  top: 50px;
  left: -5%;
  width: 320px;
  height: 180px;
  background-color: #a5aae4;
}
.formHolder__box__container {
  position: absolute;
  width: 450px;
  height: 520px;
  top: -20px;
  left: 20px;
  bottom: 20px;
  overflow: hidden;
  background-color: #fff;
  z-index: 2;
  padding: 20px;
  transition: all .5s ease;
}
.formHolder__box__container.register {
  transform: translateX(78%);
}
.formHolder__box__container.register .registerHolder {
  transform: translateX(0);
}
.formHolder__box__container.register .loginHolder {
  transform: translateX(120%);
}
.formHolder__box__container .registerHolder,
.formHolder__box__container .loginHolder {
  position: absolute;
  width: calc(100% - 40px);
  top: 20px;
  left: 20px;
  bottom: 20px;
  transition: all .5s ease;
  display: flex;
  align-items: center;
}
.formHolder__box__container .registerHolder {
  transform: translateX(-120%);
}
.formHolder__box__info {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
.formHolder__box__info > div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formHolder__box__info > div span {
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid #fff;
  color: #fff;
}
.formHolder__buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.formHolder__message {
  display: none;
  color: #C11919;
}
.formHolder__message.active {
  display: block;
}
.formHolder__loginSocial {
  text-align: left;
}
.formHolder__loginSocial .in-login-button {
  display: inline-block;
}
.formHolder__loginSocial .in-login-button img {
  height: 35px;
}
.formHolder__loginSocial > div {
  margin-top: 10px;
}
.head {
  border: 1px solid #cecece;
}
.head .wrapper {
  height: 50px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}
.head span {
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
}
.head ul li {
  display: inline-block;
}
.head ul li + li {
  margin-left: 30px;
}
.head ul li a {
  text-transform: capitalize;
  color: #949292;
}
.livepreview.visible:before {
  opacity: 0.6;
  z-index: 100;
}
.livepreview:before {
  position: absolute;
  content: 'LIVE PREVIEW';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #80b5ea;
  opacity: 0;
  z-index: -1;
  transition: all .5s ease;
}
.livepreview iframe {
  width: 100%;
  height: 0;
}
.dashboard {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.dashboard > div {
  padding: 20px 0;
}
.dashboard__codeSource {
  max-width: 60%;
  width: 100%;
}
.dashboard__codeSource div {
  margin-bottom: 30px;
}
.dashboard__codeSource textarea {
  width: 100%;
  display: block;
  resize: vertical;
  padding: 10px;
  border: 1px solid #cecece;
}
.dashboard__codeSource textarea::placeholder {
  color: #cecece;
}
.dashboard__codeSource label {
  display: block;
  margin-bottom: 5px;
}
.dashboard__fileSource {
  width: 100%;
  max-width: 30%;
}
.dashboard__fileSource label input,
.dashboard__fileSource label span {
  margin-bottom: 15px;
  display: inline-block;
}
.dashboard__fileSource__instructions {
  position: relative;
  padding: 1em 1.5em;
  margin: 2em auto;
  color: #fff;
  background-color: #4262d7;
}
.dashboard__fileSource__instructions:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 16px 16px 0;
  border-style: solid;
  border-color: #AABCFE #fff;
}
.dashboard__fileSource__instructions li {
  list-style-type: decimal;
  line-height: 1.3;
  margin-bottom: 10px;
}
.listPDF {
  padding: 30px 15px;
}
.listPDF .table tbody tr {
  transition: all .3s ease;
  display: table-row;
}
.listPDF .table tbody tr.hidden {
  display: none;
}
.listPDF .table tbody tr td {
  vertical-align: middle;
}
.listPDF .table tbody tr td:nth-of-type(3) {
  width: 40%;
}
.listPDF .table tbody tr td .codeHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listPDF .table tbody tr td textarea {
  width: 100%;
  max-width: 49%;
  height: 300px;
  resize: vertical;
}
.listPDF .table tbody tr td .pdfEdit {
  margin-top: 10px;
}
.listPDF .table tbody tr td .extendDetails {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 0 0 7px 7px;
  cursor: pointer;
  transition: all .3s ease;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI1Ljg0MXB4IiBoZWlnaHQ9IjI2LjAwNHB4IiB2aWV3Qm94PSIwLjA0NiAwLjQ5OCAyNS44NDEgMjYuMDA0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAuMDQ2IDAuNDk4IDI1Ljg0MSAyNi4wMDQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIGZpbGw9IiNCNUNDRDciIHBvaW50cz0iMC4wNDYsMi41ODIgMi4xMywwLjQ5OCAxMi45NjcsMTEuMzM0IDIzLjgwMywwLjQ5OCAyNS44ODcsMi41ODIgMTIuOTY3LDE1LjUwMiAiLz48cG9seWdvbiBmaWxsPSIjQjVDQ0Q3IiBwb2ludHM9IjAuMDQ2LDEzLjU4MiAyLjEzLDExLjQ5OCAxMi45NjcsMjIuMzM0IDIzLjgwMywxMS40OTggMjUuODg3LDEzLjU4MiAxMi45NjcsMjYuNTAyICIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
}
.listPDF .table tbody tr td .extendDetails.active {
  transform: rotate(180deg);
}
.listPDF .picture img {
  max-width: 200px;
}
.listUsers {
  padding: 30px 15px;
}
[data-overlay="popup"] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: -1;
  transition: all .3s ease;
}
[data-overlay="popup"].visible {
  opacity: 1;
  z-index: 20;
}
[data-popup] {
  position: fixed;
  min-width: 250px;
  min-height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  transition: all .4s ease;
  visibility: hidden;
  padding: 30px;
}
[data-popup].active {
  z-index: 21;
  opacity: 1;
  visibility: visible;
}
[data-popup] h2 {
  font-size: 18px;
  line-height: 20px;
  color: #333;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #DCDBDB;
}
[data-popup] .popup__actions {
  position: relative;
  text-align: center;
  margin-top: 30px;
}
[data-popup] .popup__actions [data-popup-close] {
  position: static;
  width: auto;
  height: auto;
  color: #fff;
}
[data-popup][data-popup="editUserData"] {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
}
[data-popup][data-popup="editUserData"] form {
  margin-top: 20px;
}
[data-popup][data-popup="editUserData"] form div {
  text-align: center;
}
[data-popup][data-popup="editUserData"] form div + div {
  margin-top: 15px;
}
[data-popup][data-popup="editUserData"] form div label {
  display: inline-block;
  width: 150px;
  text-transform: capitalize;
  text-align: left;
}
[data-popup][data-popup="editUserData"] form div input {
  width: 250px;
  display: inline-block;
  border: 1px solid #cecece;
  height: 45px;
  padding: 0 10px;
}
[data-popup][data-popup="deleteUser"] {
  background-color: #fff;
}
[data-popup][data-popup="deletepdf"] {
  background-color: #fff;
}
[data-popup][data-popup="deletepdf"] .popup__actions [data-popup-close] {
  padding: 0;
}
[data-popup][data-popup="sendpdf"] {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
}
[data-popup][data-popup="sendpdf"] input[type="email"] {
  display: block;
  border: 1px solid #cecece;
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  padding: 0 10px;
}
[data-popup][data-popup="sendpdf"] .popup__actions [data-popup-close] {
  padding: 0;
}
[data-popup-close] {
  position: absolute;
  width: 16px;
  right: 10px;
  top: 10px;
  z-index: 22;
  font-weight: 700;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  padding: 3px;
}
@media only screen and (min-width: 1025px) {
  .btn:hover {
    background-color: #2440a5;
  }
}
